<template>
  <va-form class="mt-5" tag="form" @submit.prevent="addEditor">
    <va-input v-model="editor.name" :label="$t('name')" required />

    <va-input
      class="mt-4"
      v-model="editor.email"
      type="email"
      :label="$t('email')"
      required
    />
    <va-input
      class="mt-4"
      v-model="editor.tel"
      :label="$t('tel')"
      placeholder="+### ##########"
      :mask="{ blocks: [3, 9] }"
      required
    />
    <va-input
      class="mt-4"
      v-model="editor.password"
      type="password"
      :label="$t('password')"
      required
    />

    <va-button
      type="submit"
      class="mt-4"
      :disabled="checkForm"
      :loading="loading"
    >
      {{ $t("add") }}
    </va-button>
  </va-form>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  name: "add-editor-form",
  data() {
    return {
      loading: false,
      editor: { name: "", password: "", tel: null, email: "" },
    };
  },
  methods: {
    async addEditor() {
      const ADD_EDITOR = gql`
        mutation (
          $name: String
          $email: String
          $tel: String
          $lang: String
          $password: String
        ) {
          register(
            registerInput: {
              name: $name
              email: $email
              tel: $tel
              password: $password
              lang: $lang
            }
          ) {
            id
          }
        }
      `;
      const UPDATE_USER_ROLE = gql`
        mutation ($id: Int) {
          UsersUpdate(users: { id: $id, user_role_id: 4 }) {
            name
          }
        }
      `;
      try {
        this.loading = true;
        const response = await request(
          this.$store.state.appUrl,
          ADD_EDITOR,
          this.editor
        );
        await request(this.$store.state.appUrl, UPDATE_USER_ROLE, {
          id: response.register.id,
        });
        location.reload();
        document.querySelector(".va-modal__close").click();
        this.loading = false;
        this.$swal.fire({
          icon: "success",
          title: this.$t("success"),
          text: this.$t("add_editor_success"),
          confirmButtonColor: "#023b81",
          confirmButtonText: this.$t("ok"),
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal.fire({
          icon: "error",
          title: this.$t("failed"),
          text: this.$t("add_editor_fail"),
          confirmButtonColor: "#023b81",
          confirmButtonText: this.$t("ok"),
        });
      }
    },
  },
  provide: ["getEditors"],
  computed: {
    checkForm() {
      if (
        !this.editor.name ||
        !this.editor.email ||
        !this.editor.password ||
        !this.editor.tel
      )
        return true;
    },
  },
};
</script>
